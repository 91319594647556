import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'

import App from 'App'
import 'bootstrap/scss/bootstrap.scss'
import 'assets/scss/main.scss'
import en from './lang/en.json'
import sw from './lang/sw.json'

const root = ReactDOM.createRoot(document.getElementById('root'))

const defaultLocale = 'sw'
const userLocale = window.navigator.language || defaultLocale

const messages = {
  en,
  sw
}

const lang = localStorage.getItem('Lang')

root.render(
  <React.StrictMode>
    <IntlProvider messages={messages[lang] || sw} locale={userLocale} defaultLocale='sw'>
      <App />
    </IntlProvider>
  </React.StrictMode>
)
