import React, { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Loading from 'shared/components/loading'
const AllRoutes = React.lazy(() => import('routes'))

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			onSettled: (_d, e) => {
				if (e?.message === 'Network Error') {
					queryClient.invalidateQueries('toast')
					queryClient.setQueryData('message', () => ({ message: e?.message, type: 'error' }))
				}
				if (e?.response?.status > 300) {
					queryClient.invalidateQueries('toast')
					queryClient.setQueryData('message', () => ({
						message: e?.response?.data.message || e?.response?.data || e?.message,
						type: 'error',
					}))
				}
			},
		},
		mutations: {
			onSettled: (_d, e) => {
				if (e?.message === 'Network Error') {
					queryClient.invalidateQueries('toast')
					queryClient.setQueryData('message', () => ({ message: e?.message, type: 'error' }))
				}
				if (e?.response?.status > 300) {
					queryClient.invalidateQueries('toast')
					queryClient.setQueryData('message', () => ({
						message: e?.response?.data?.message || e?.response?.data || e?.message,
						type: 'error',
					}))
				}
			},
		},
		message: (msg, type) => {
			queryClient.invalidateQueries('toast')
			queryClient.setQueryData('message', () => ({ message: msg, type }))
		},
	},
})

function App() {
	// console.error = () => {}
	// console.warn = () => {}
	// console.log = () => {}
	return (
		<QueryClientProvider client={queryClient}>
			<Suspense fallback={<Loading />}>
				<AllRoutes />
			</Suspense>
		</QueryClientProvider>
	)
}

export default App
